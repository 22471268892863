import { Route, Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import OwnerLayout from './components/OwnerLayout';
import DriverLayout from './components/DriverLayout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ConfirmEmail from './pages/ConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Account from './pages/Account';
import Support from './pages/Support';
import MyShift from './pages/MyShift';
import Shift from './pages/Shift';
import LocalStorageService from './services/LocalStorageService';
import { getRoleBasedLandingPath, isDriver } from './utils/common';
import Vehicles from './pages/Vehicles';
import Stores from './pages/Stores';
import Staff from './pages/Staff';
import ShiftDetail from './pages/ShiftDetail';
import ShiftNRDetail from './pages/ShiftNRDetail';
import VehicleDetail from './pages/VehicleDetail';
import StaffDetail from './pages/StaffDetail';
import PrecheckQuestionDetail from './pages/PrecheckQuestionDetail';
import PrecheckQuestion from './pages/PrecheckQuestions';
import PredefinedShiftCommentDetail from './pages/PredefinedShiftCommentDetail';
import PredefinedShiftComment from './pages/PredefinedShiftComment';
import StoreDetail from './pages/StoreDetail';
import ShiftPreCheck from './pages/ShiftPreCheck';
import StartAdhocShift from './pages/StartAdhocShift';
import Payroll from './pages/Payroll';
import PayrollPeriod from './pages/PayrollPeriod';
import Company from './pages/Company';
import EditShift from './pages/EditShift';
import ShiftsTracker from './pages/ShiftsTracker';
import Linfox from './pages/Linfox';
import SubmitShift from './pages/SubmitShift';
import Invoices from './pages/Invoices';
import PayrollSettings from './pages/PayrollSettings';
import PayrollAddOns from './pages/PayrollAddOns';
import PayrollAddOnDetail from './pages/PayrollAddOnDetail';
import PublicHolidays from './pages/PublicHolidays';
import PayrollSummary from './pages/PayrollSummary';

const localStorageService = LocalStorageService.getService();

function DriverRoute({ ...props }) {
  const roles = localStorageService.getUserRoles();

  if (localStorageService.hasValidToken() && isDriver(roles)) {
    return <Route {...props} />;
  }

  return <Navigate to="/" />;
}

function OwnerRoute({ ...props }) {
  const roles = localStorageService.getUserRoles();

  if (localStorageService.hasValidToken() && !isDriver(roles)) {
    return <Route {...props} />;
  }

  return <Navigate to="/" />;
}

function BypassLoginRoute({ ...props }) {
  const roles = localStorageService.getUserRoles();
  const companyProfile = localStorageService.getCompanyProfile();
  if (localStorageService.hasValidToken()) {
    return <Navigate to={getRoleBasedLandingPath(roles)} />;
  }

  return <Route {...props} />;
}

function LoginRequiredRoute({ ...props }) {
  if (localStorageService.hasValidToken()) {
    return <Route {...props} />;
  }

  return <Navigate to="/" />;
}

function LogoutRequiredRoute({ ...props }): any {
  localStorageService.clearToken();

  return <Route {...props} />;
}

const routes = (
  <>
    <OwnerRoute path="app" element={<OwnerLayout />}>
      <Route path="/shift" element={<Navigate to="/app/shift/list" />} />

      <LoginRequiredRoute path="shift/list" element={<Shift />} />
      <LoginRequiredRoute path="shift/list/:id" element={<ShiftDetail />} />
      <LoginRequiredRoute path="shift/list/:id/edit" element={<EditShift />} />

      <LoginRequiredRoute path="shift/schedule" element={<EditShift />} />

      <LoginRequiredRoute
        path="shift/comment"
        element={<PredefinedShiftComment />}
      />
      <LoginRequiredRoute
        path="shift/comment/:id"
        element={<PredefinedShiftCommentDetail />}
      />

      <LoginRequiredRoute
        path="shift/question"
        element={<PrecheckQuestion />}
      />
      <LoginRequiredRoute
        path="shift/question/:id"
        element={<PrecheckQuestionDetail />}
      />

      <LoginRequiredRoute path="linfox" element={<Linfox />} />
      <LoginRequiredRoute path="linfox/:id" element={<ShiftNRDetail />} />

      <LoginRequiredRoute path="tracker" element={<ShiftsTracker />} />

      <LoginRequiredRoute path="staff" element={<Staff />} />
      <LoginRequiredRoute path="staff/:id" element={<StaffDetail />} />

      <LoginRequiredRoute path="vehicle" element={<Vehicles />} />
      <LoginRequiredRoute path="vehicle/:id" element={<VehicleDetail />} />

      <LoginRequiredRoute path="jobsite" element={<Stores />} />
      <LoginRequiredRoute path="jobsite/:id" element={<StoreDetail />} />

      <LoginRequiredRoute
        path="payroll/addons/:addOnType"
        element={<PayrollAddOns />}
      />
      <LoginRequiredRoute
        path="payroll/addons/:addOnType/:id"
        element={<PayrollAddOnDetail />}
      />
      <Route
        path="payroll/addons"
        element={<Navigate to="/app/payroll/addons/jobSite" />}
      />
      <LoginRequiredRoute
        path="payroll/settings"
        element={<PayrollSettings />}
      />
      <LoginRequiredRoute path="payroll/periods" element={<PayrollPeriod />} />
      <LoginRequiredRoute
        path="payroll/summaries"
        element={<PayrollSummary />}
      />
      <LoginRequiredRoute path="payroll/periods/:id" element={<Payroll />} />
      <LoginRequiredRoute
        path="payroll/holidays"
        element={<PublicHolidays />}
      />

      <LoginRequiredRoute path="profile" element={<Account />} />
      <LoginRequiredRoute path="support" element={<Support />} />
      <LoginRequiredRoute path="company" element={<Company />} />

      <LoginRequiredRoute path="invoice" element={<Invoices />} />

      <Route path="*" element={<Navigate to="/404" replace={false} />} />
    </OwnerRoute>

    <DriverRoute path="employee" element={<DriverLayout />}>
      <LoginRequiredRoute path="shift" element={<MyShift />} />
      <LoginRequiredRoute path="shift/:id" element={<ShiftDetail />} />
      <LoginRequiredRoute path="shift/:id/submit" element={<SubmitShift />} />
      <LoginRequiredRoute path="shift/:id/start" element={<ShiftPreCheck />} />
      <LoginRequiredRoute
        path="adhocshift/start"
        element={<StartAdhocShift />}
      />
      <LoginRequiredRoute path="profile" element={<Account />} />
      <LoginRequiredRoute path="support" element={<Support />} />

      <Route path="*" element={<Navigate to="/404" replace={false} />} />
    </DriverRoute>

    <Route path="/" element={<MainLayout />}>
      <BypassLoginRoute path="login" element={<Login />} />
      <LoginRequiredRoute path="changepassword" element={<ChangePassword />} />
      <LogoutRequiredRoute path="forgotpassword" element={<ForgotPassword />} />
      <LogoutRequiredRoute path="confirmemail/:id" element={<ConfirmEmail />} />
      <LogoutRequiredRoute
        path="resetpassword/:id"
        element={<ResetPassword />}
      />
      <Route path="404" element={<NotFound />} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<Navigate to="/404" replace={false} />} />
    </Route>
  </>
);

export default routes;
