import { AxiosResponse } from 'axios';
import FormattedVehicle from '../models/FormattedVehicle';
import AppConstants from '../constants/AppConstants';
import EnumService from './EnumService';
import { Client, VehicleReq, VehicleRes, VehicleResPage } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const VehiclesService = {
  getAllVehicles: () => Promise.all([
    EnumService.getEnums('vehicleTypes'),
    EnumService.getEnums('vehicleStatuses'),
    EnumService.getEnums('states'),
    configureAxios().page11({
      pageNumber: 1,
      pageSize: AppConstants.MAX_PAGE_SIZE
    }).then((resp: VehicleResPage) => {
      const result: Array<VehicleRes> = resp?.items || [];
      return result.filter((question: any) => !question.isSoftDeleted);
    })
  ]).then((resp) => {
    const vehicleTypes = resp[0];
    const vehicleStatuses = resp[1];
    const states = resp[2];
    const vehicles: Array<VehicleRes> = resp[3];
    return vehicles.map((vehicle: any) => new FormattedVehicle(vehicle, vehicleStatuses, vehicleTypes, states));
  }),
  getVehicleById: (id: number) => configureAxios().get16(id).then((resp: VehicleRes) => new FormattedVehicle(resp)),
  createVehicle: (data: VehicleReq) => configureAxios().create8(data),
  updateVehicle: (id: number, data: VehicleReq) => configureAxios().updatePOST13(id, data),
  deleteVehicle: (id: number = 0) => configureAxios().softDelete5(id)
};

export default VehiclesService;
