import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox,
  Hidden
} from '@mui/material';
import { connect } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import AppConstants from '../constants/AppConstants';
import AllShifts from '../components/shift/AllShifts';
import { refreshDataWithDateRange, changeDateRange, selectStates, reset } from '../store/actions/shiftActions';
import DateRangeSelector from '../components/DateRangeSelector';
import EnumService from '../services/EnumService';
import { RootState } from '../store/store';
import { EnumRes } from '../proxy/proxy';

interface Props {
  startDate: string,
  endDate: string,
  selectedStates: Array<number>,

  setStates: (states: Array<number>) => void,
  onDateRangeChange: (startDate: string, endDate: string) => any,
  fetchAllRows: (startDate?: string, endDate?: string) => void,
  resetPage: () => void,
}

const MyShift: React.FC<Props> = ({
  startDate,
  endDate,
  selectedStates,
  setStates,
  fetchAllRows,
  resetPage,
  onDateRangeChange
}) => {
  const [allStates, setAllStates] = useState<Array<EnumRes>>([]);

  const onStatesSelect = (event: any) => {
    setStates(event?.target?.value ?? []);
  };

  const handleDateRangeChange = (start: string, end: string) => {
    onDateRangeChange(start, end);
    if (start && end) {
      fetchAllRows();
    }
  };

  useEffect(() => {
    fetchAllRows();
    EnumService.getEnums('states').then((states) => {
      setAllStates(states);
    }).catch();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Shifts | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              title="My Shift"
              action={
                (
                  <IconButton
                    onClick={() => {
                      resetPage();
                      fetchAllRows();
                    }}
                    data-auto-id="shiftRefreshIconButton"
                  >
                    <RefreshIcon />
                  </IconButton>
                )
              }
            />
            <Divider />
            <CardContent>
              <Hidden mdDown>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DateRangeSelector startDate={startDate} endDate={endDate} allowFutureDates onChange={handleDateRangeChange} />
                  <TextField
                    sx={{ width: '200px' }}
                    size="small"
                    label="States"
                    name="states"
                    onChange={onStatesSelect}
                    onBlur={onStatesSelect}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
                    }}
                    value={selectedStates}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DateRangeSelector startDate={startDate} endDate={endDate} allowFutureDates onChange={handleDateRangeChange} />
                  <TextField
                    sx={{ marginTop: '1rem' }}
                    fullWidth
                    size="small"
                    label="States"
                    name="states"
                    onChange={onStatesSelect}
                    onBlur={onStatesSelect}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected: any) => selected.map((x: any) => allStates.find((state: any) => state.id === x)?.value).join(', ')
                    }}
                    value={selectedStates}
                  >
                    {allStates.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={selectedStates.includes(option.id ?? 0)} />
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Hidden>
              <AllShifts />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  startDate: state.shift?.startDate,
  endDate: state.shift?.endDate,
  selectedStates: state.shift.states ?? [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: (startDate?: string, endDate?: string) => dispatch(refreshDataWithDateRange(startDate, endDate)),
  onDateRangeChange: (startDate: string, endDate: string) => dispatch(changeDateRange(startDate, endDate)),
  setStates: (states: Array<number>) => dispatch(selectStates(states)),
  resetPage: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyShift);
