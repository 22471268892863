import axios from 'axios';
import AppConstants from '../constants/AppConstants';

const TokenService = {
  signIn: (userName: string, password:string, rememberMe: any) => axios.post(`${AppConstants.BASE_API_URL}/api/Token`, {
    userName,
    password,
    client_Id: AppConstants.APP_CLIENT_ID,
    rememberMe
  }),
  getTokenFromRefreshToken: (token: string, refreshToken: string) => axios.post(`${AppConstants.BASE_API_URL}/api/Token/Refresh`, {
    access_Token: token,
    refresh_Token: refreshToken,
    client_Id: AppConstants.APP_CLIENT_ID
  })
};

export default TokenService;
