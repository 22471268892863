const AppConstants = {
  APP_NAME: 'TruckSight',
  SUPPORT_PHONE_NO: 'XXXX-XXXX-XXXX-XXXX',
  SUPPORT_EMAIL: 'support@rightomation.com.au',
  MAX_PAGE_SIZE: 5000,
  GRID_PAGE_SIZE: 10,
  MOBILE_PAGE_SIZE: 5,
  BREAK_BREACH_TIME: 300, // Minutes
  BREAK_MINIMUM_TIME: 30, // Minutes
  MAXIMUM_CONSECUTIVE_WORKING_DAYS: 6, // days
  DRIVING_LICENSE_EXPIERY_WARNING_BEFORE_DAYS: 30, // days
  VEHICLE_INSURANCE_EXPIERY_WARNING_BEFORE_DAYS: 30, // days
  VEHICLE_REGISTRATION_EXPIERY_WARNING_BEFORE_DAYS: 60, // days
  VEHICLE_SERVICE_DUE_WARNING_BEFORE_DAYS: 30, // days
  VEHICLE_SERVICE_DUE_WARNING_BEFORE_KMS: 500, // KMs
  BREAK_BREACH_WARN_TIME: 15, // Minutes
  APP_CLIENT_ID: 'AngularWebApp',
  BASE_API_URL: process.env.REACT_APP_API_URL || '',
  UI_VERSION: process.env.REACT_APP_VERSION || '',
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  DATE_FORMATS: {
    LONG: 'DD MMM yyyy, HH:mm', // 11 Sept 2021, 17:55
    DATE: 'DD MMM yyyy', // 11 Sept 2021
    TIME: 'HH:mm', // 17:55
  },
  // 18005551234
  // 1 800 555 1234
  // +1 800 555-1234
  // +86 800 555 1234
  // 1-800-555-1234
  // 1 (800) 555-1234
  // (800)555-1234
  // (800) 555-1234
  // (800)5551234
  // 800-555-1234
  // 800.555.1234
  // 800 555 1234x5678
  // 8005551234 x5678
  PHONE_NO_REGEXP: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  // +910123456789
  // 0123456789
  // +91 0123456789
  MOBILE_NO_REGEXP: /^([+]\d{1,2}[ ]?)?\d{7,10}$/,

  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  TOKEN_EXPIRES_IN: 'TOKEN_EXPIRES_IN',
  TOKEN_VALID_TILL: 'TOKEN_VALID_TILL',
  USER_PROFILE: 'USER_PROFILE',
  ROLES: 'USER_ROLES',
  COMPANY_PROFILE: 'COMPANY_PROFILE',
  USER_ROLES: {
    DRIVER: 'Driver',
    ACCOUNTANT: 'Accountant',
    MANAGER: 'Manager',
    OWNER: 'Owner',
  }
};

export default AppConstants;
