import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import AppNavbar from './AppNavbar';

const DriverLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DriverLayoutWrapper = experimentalStyled('div')(
  () => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  })
);

const DriverLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DriverLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DriverLayout = () => (
  <DriverLayoutRoot>
    <AppNavbar />
    <DriverLayoutWrapper>
      <DriverLayoutContainer>
        <DriverLayoutContent>
          <Outlet />
        </DriverLayoutContent>
      </DriverLayoutContainer>
    </DriverLayoutWrapper>
  </DriverLayoutRoot>
);

export default DriverLayout;
